import { Settings } from '../models/settings';

export class Global {
  private static instance: Global;

  private settings: Settings;
  constructor() {
    //Empty
  }
  static get Instance() {
    if (!this.instance) {
      this.instance = new Global();
    }
    return this.instance;
  }
  static get Settings(): Settings {
    if (!this.Instance.settings) {
      throw 'Application settings are not set, Please read define at AppModule file';
    }
    return this.Instance.settings;
  }
  static set Settings(newSettings: Settings) {
    this.Instance.settings = newSettings;
  }

  static backEndUrl(replace?: boolean): string {
    const settings = this.Settings;
    const domain = replace ? settings.sensewareApiHost : settings.avlApiHost;
    const url = settings.apiProtocol + '://' + domain;
    return settings.apiPort ? url + ':' + settings.apiPort + '/' : url + '/';
  }

  static restUrl(replace?: boolean): string {
    const settings = this.Settings;
    // if there is no endpoint return the backEndUrl
    return settings.apiEndPoint
      ? this.backEndUrl(replace) + settings.apiEndPoint + '/'
      : this.backEndUrl(replace);
  }
  static get jsonUrl(): string {
    const settings = this.Settings;
    // if there is no endpoint return the backEndUrl
    return settings.jsonHost + '/';
  }
}
